import React, { Fragment } from 'react';
import "../styles/home.scss";
import Dispositivos from "../assets/Mockup.png";
import UsandoCelular from "../assets/usandoCelular.jpg";
import Footer from '../components/footer';
import Header from '../components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCalendarDays, faChartLine, faCamera, faCarSide, faEye, faGraduationCap, faHandSparkles, faPlus, faScissors, faScrewdriverWrench, faUserDoctor, faGear } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck, faFileLines } from '@fortawesome/free-regular-svg-icons';

function HomeScreen () {
    return (
        <Fragment>
            <Header/>
            <main id='page-home'>
                <section id='section-home-introduct'>
                    <div>
                        <h1><span>Gestão eficiente</span> e <span>otimização de tempo</span> para sua empresa</h1>
                        {/* <h4>Tudo que sua empresa precisa para se destacar no m</h4> */}
                        {/* <h4>Transforme a gestão de horários sua empresa em uma experiência eficiente e intuitiva. Potencialize a produtividade com a nossa plataforma projetada para simplificar e aprimorar a organização do seu tempo e fazer você se destacar!</h4> */}
                        {/* <h4>Transforme a gestão de horários da sua empresa em uma experiência eficiente e intuitiva. Potencialize a produtividade com nossa plataforma e se destaque!</h4> */}
                        <h4>Agende seus atendimentos de qualquer lugar e de forma rápida. Tenha tudo que precisa para gestão do seu negócio em um só lugar e potencialize a produtividade com nossa plataforma.</h4>
                        <a href='/contratar' target='_blank'>Quero me destacar <span><FontAwesomeIcon icon={faAngleRight}/></span></a>
                    </div>
                    <img src={Dispositivos} alt='Dispositivos eletrônicos com a página de agendamentos da BookingHub na tela'/>
                </section>
                {/* descrição */}
                <section id='section-home-about'>
                    <div className='image-section-about'>
                        <img src={UsandoCelular} alt='Pessoa usando celular, demonstrando o uso do BookingHub em qualquer lugar'/>
                        <div className='object-image1'></div>
                        <div className='object-image2'></div>
                    </div>
                    <div className='texts-section-about'>
                        <h2>Os <span>benefícios</span> que seu negócio precisa</h2>
                        {/* <h3>Conte com uma plataforma poderosa que impulsiona a eficiência, feita exclusivamente para transformar a maneira como você gerencia seu tempo e compromissos. Nós entendemos que cada minuto conta, e é por isso que desenvolvemos uma solução inovadora que simplifica e aprimora o processo de agendamento para você e seus clientes. Tenha ao seu alcance e de forma rápida tudo sobre seu negócio, desde horário que seus clientes estão agendados até o controle financeiro de sua empresa.</h3> */}
                        {/* <h3>Conte com uma plataforma poderosa que impulsiona a eficiência, feita exclusivamente para transformar a maneira como você gerencia seu tempo e compromissos. Nós entendemos que cada minuto conta, e é por isso que desenvolvemos uma solução inovadora que simplifica e aprimora o processo de agendamento para você e seus clientes. Tenha ao seu alcance e de forma rápida tudo sobre seu negócio, desde horário que seus clientes estão agendados até um relatório completo de sua empresa.</h3> */}
                            {/* <h3>Tenha ao seu alcance e de forma rápida tudo sobre seu negócio, desde horário que seus clientes estão agendados até um relatório completo de sua empresa.</h3> */}
                        {/* <h3>Conte com vários recursos poderosos que impulsionam a eficiência e revolucione a forma de gerenciar horários na sua empresa. Com a BookingHub isso se torna acessível de forma intuitiva e rápida, </h3> */}
                        <ul>
                            <li><div><FontAwesomeIcon icon={faGear}/></div> <p>Gerencie seus atendimentos de forma fácil e eficiente, de qualquer lugar e a qualquer momento</p></li>
                            <li><div><FontAwesomeIcon icon={faCalendarCheck}/></div> <p>Permita que seus clientes escolham os melhores horários e façam os agendamentos de forma simplificada</p></li>
                            <li><div><FontAwesomeIcon icon={faFileLines}/></div> <p>Tenha relatórios com informações importantes sobre tudo do seu negócio</p></li>
                            <li><div><FontAwesomeIcon icon={faChartLine}/></div> <p>Calcule as comissões, adicione receitas e despesas, tudo em um só lugar</p></li>
                        </ul>
                    </div>
                </section>

                <section id='section-home-companys'>
                    <h2>Independente do nicho, <span>nós atendemos</span></h2>
                    <h3>Seja qual for o ramo da sua empresa, nossa solução é para todas as que fazem gestão de horários.<br/>Conheça os principais ramos:</h3>
                    <ul>
                        <li><FontAwesomeIcon icon={faScissors}/>Barbearias</li>
                        <li><FontAwesomeIcon icon={faScissors}/>Salões de Beleza</li>
                        <li><FontAwesomeIcon icon={faCarSide}/>Lava Jatos</li>
                        <li><FontAwesomeIcon icon={faCarSide}/>Estéticas Automotivas</li>
                        <li><FontAwesomeIcon icon={faScrewdriverWrench}/>Mecânicas</li>
                        <li><FontAwesomeIcon icon={faHandSparkles}/>Nail Designers/Manicures</li>
                        <li><FontAwesomeIcon icon={faEye}/>Extensionistas de Cilios</li>
                        <li><FontAwesomeIcon icon={faEye}/>Designers de Sobrancelhas</li>
                        <li><FontAwesomeIcon icon={faGraduationCap}/>Professores/Instrutores</li>
                        <li><FontAwesomeIcon icon={faCamera}/>Fotógrafos</li>
                        <li><FontAwesomeIcon icon={faUserDoctor}/>Psicólogos</li>
                        <li><FontAwesomeIcon icon={faPlus}/>Entre outros</li>
                    </ul>
                </section>

                {/* recursos */}
                <section id='section-home-features'>
                    <div>
                        {/* <img src={ProblemSolved} alt='Ilustração de uma pessoa com um lápis e marcando como concluido'/> */}
                        <h2><span>Resolvemos</span> os problemas do seu negócio</h2>
                        {/* <h3>Descubra recursos poderosos que irão te ajudar a resolver os problemas que você enfrenta em seu negócio.</h3> */}
                        <h3>Tenha a sua disposição recursos poderosos que irão te ajudar a resolver os problemas que você enfrenta em seu negócio, facilitando a sua gestão e permitindo que você foque no crescimento.</h3>
                        <a href='/contratar' target='_blank'>Quero focar no crescimento <span><FontAwesomeIcon icon={faAngleRight}/></span></a>
                    </div>
                    {/* <h2>Recursos que fazem a diferença</h2> */}
                    {/* <h3>Descubra recursos poderosos que impulsionam a eficiência e revolucione a forma de gerenciar horários na sua empresa.</h3> */}
                    <ul>
                        <li>
                            {/* <FontAwesomeIcon icon={faCalendarDays}/> */}
                            <div>
                                <h4>Agendamentos</h4>
                                <p>Tenha uma gestão completa dos horários. Deixe para os desatualizados usarem agendas, agende seus atendimentos a qualquer momento e em qualquer lugar.</p>
                            </div>
                            {/* <p>Tenha uma gestão completa dos horários, use filtros de busca, altere o status dos agendamentos e visualize tudo facilmente.</p> */}
                        </li>
                        <li>
                            {/* <FontAwesomeIcon icon={faCalendarCheck}/> */}
                            <div>
                                <h4>Agenda Rápida</h4>
                                <p>Chega de ficar enviando mensagens com horários disponíveis. Permita que seus clientes escolham entre os melhores horários disponíveis de forma simples.</p>
                            </div>
                            {/* <p>Permita que seus clientes agende seus próprios horários de forma simples, otimizando o seu trabalho e melhorando a experiência do cliente.</p> */}
                        </li>
                        <li>
                            {/* <FontAwesomeIcon icon={faChartLine}/> */}
                            <div>
                                <h4>Gestão financeira</h4>
                                <p>Conte com a nossa ajuda para gerenciar as finanças de seu negócio. Calcule as comissões dos profissionais de forma automática, cadastre contas e muito mais.</p>
                            </div>
                            {/* <p>Conte com a nossa ajuda para gerenciar as finanças de seu negócio. Calcule as comissões dos profissionais, cadastre contas e muito mais.</p> */}
                        </li>
                        {/* <li>
                            <FontAwesomeIcon icon={faChartLine}/>
                            <h4>Relatórios</h4>
                            <p>Conte com relatórios completos sobre sua operação, tenha em mãos a quantidade de atendimentos, comparativo entre periodos e muito mais.</p>
                        </li> */}
                        {/* <li>
                            <FontAwesomeIcon icon={faLink}/>
                            <h4>HubLinks</h4>
                            <p>Uma ferramenta para divulgar links em suas redes sociais, coloque desde suas redes sociais até o seu whatsapp comercial.</p>
                        </li> */}
                        {/* <li>
                            <FontAwesomeIcon icon={faFileInvoiceDollar}/>
                            <h4>Financeiro</h4>
                            <p>Faça a gestão financeira de seu estabelecimento direto pela nossa plataforma, facilitando ainda mais o seu dia a dia.</p>
                        </li> */}
                        {/* <li>
                            <FontAwesomeIcon icon={faGears}/>
                            <h4>Serviços</h4>
                            <p>Cadastre todos os serviços que sua empresa oferece e selecione os serão necessários em cada agendamento.</p>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faUsers}/>
                            <h4>Colaboradores</h4>
                            <p>Conte com a ajuda de seus colaboradores para agendar, cadastrar novos serviços e atualizar status dos horários.</p>
                        </li> */}
                    </ul>
                </section>

                {/* call to action */}
                <section className='section-cta'>
                    <div>
                        <h2>Gerencie seus atendimentos em um único lugar, a qualquer momento</h2>
                        <h3>Inscreva-se agora mesmo e garanta <span style={{fontWeight: 600}}>15 dias grátis</span> para testar, sem compromisso nenhum!</h3>
                        <a href='/contratar' target='_blank'>Garantir os 15 dias grátis <span><FontAwesomeIcon icon={faAngleRight}/></span></a>
                    </div>
                </section>
            </main>
            <Footer/>
        </Fragment>
    );
};
   
export default HomeScreen;