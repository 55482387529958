import React, { Fragment, useState } from 'react';
import "../styles/faq.scss";
import Footer from '../components/footer';
import Header from '../components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCheck, faSearch } from '@fortawesome/free-solid-svg-icons';

function FaqScreen () {
    document.title = `FAQ - BookingHub`;
    const [categoriaSelecionada, setCategoriaSelecionada] = useState('principais-duvidas');
    const [finds, setFinds] = useState('');
    const [questionSelected, setQuestionSelected] = useState(null)

    const toggleFaq = (i) => {
        if (questionSelected === i) {
            setQuestionSelected(null);
        } else {
            setQuestionSelected(i)
        }
    };

    const faqs = [
        {
            id: 1,
            pergunta: 'O que é a BookingHub?',
            resposta: 'A BookingHub é uma startup que oferece um software como serviço, nesse caso o serviço principal é agendamentos online. Nós criamos uma plataforma onde é possível ter controle total dos agendamentos de sua empresa, seja qual for o nicho.',
            categoria: 'principais-duvidas'
        },
        {
            id: 10,
            pergunta: 'Como funciona os 15 dias gratuitos de teste?',
            resposta: 'Nós oferecemos 15 dias gratuitos para o primeiro cadastro de uma empresa. Dentro desses 15 dias, é possível o usuário conhecer todas as ferramentas disponíveis na plataforma, com base no plano pré-contratado.',
            categoria: 'principais-duvidas'
        },
        {
            id: 11,
            pergunta: 'Terminou meus 15 dias de teste gratuitos, o que fazer agora?',
            resposta: 'Quando estiver chegando ao fim dos 15 dias, você será avisado com antecedência e poderá fazer a contratação do plano que melhor se adapta a sua escolha, podendo optar por pagamento via PIX ou Cartão de Crédito, na recorrência mensal ou anual.',
            // resposta: 'Se os 15 dias expiraram e você configurou o pagamento como cartão de crédito, será debitado automaticamente ao final dos 15 dias. Caso tenha configurado o pagamento de outra forma, é necessário entrar na aba de gestão e depois na seção de "Faturamento" para gerar a guia para pagamento.',
            categoria: 'principais-duvidas'
        },
        {
            id: 12,
            pergunta: 'Existe um limite para o número de usuários que posso ter na minha empresa?',
            resposta: 'Sim, esse número varia de acordo com o plano contratado. A quantidade de usuários varia de 2 colaboradores no plano básico até 6 no plano profissional.',
            categoria: 'principais-duvidas'
        },
        {
            id: 13,
            pergunta: 'Como posso solicitar novos recursos ou sugerir melhorias para a plataforma?',
            resposta: 'Para solicitar novos recursos ou melhorias, pode ser feito atráves de nosso e-mail contato@bookinghub.com.br ou diretamente na plataforma atráves da opção de contato.',
            categoria: 'principais-duvidas'
        },
        // {
        //     id: 14,
        //     pergunta: 'Quais são os termos de serviço e as condições de uso da plataforma?',
        //     resposta: 't',
        //     categoria: 'principais-duvidas'
        // },
        {
            id: 2,
            pergunta: 'Onde posso acessar?',
            resposta: 'O acesso à plataforma pode ser feito de qualquer navegador, em qualquer dispositivo. Em qualquer plano contratado, é possível acessar em qualquer dispositivo, seja ele um smartphone, um notebook ou um computador. O único requisito é estar conectado à internet.',
            categoria: 'plataforma'
        },
        {
            id: 20,
            pergunta: 'Como posso fazer download em meu smartphone ou tablet?',
            resposta: 'Utilizando o navegador de preferência, busque pela opção "Instalar aplicativo", será possível instalar a plataforma para usar de forma mais rápida em seu dispositivo móvel.',
            categoria: 'plataforma'
        },
        {
            id: 21,
            pergunta: 'É garantido a segurança dos meus dados e informações?',
            resposta: 'Com certeza. Os dados e informações dos usuário e empresas são guardados em locais seguros, com acesso controlado e também são criptografados, para melhor segurança de todos.',
            categoria: 'plataforma'
        },
        {
            id: 22,
            pergunta: 'Existe um suporte técnico em caso de problemas ou dúvidas?',
            resposta: 'Sim, o suporte pode ser solicitado através do e-mail suporte@bookinghub.com.br ou pela opção de contato direto na plataforma.',
            categoria: 'plataforma'
        },
        {
            id: 3,
            pergunta: 'Quais métodos de pagamento são aceitos?',
            resposta: 'Os métodos de pagamento aceitos atualmente são PIX e Cartão de Crédito.',
            categoria: 'planos-cobranças'
        },
        {
            id: 30,
            pergunta: 'Posso mudar meu plano de assinatura depois de me inscrever?',
            resposta: 'Sim, a alteração do plano de assinatura pode ser feito direto na plataforma após a contratação.',
            categoria: 'planos-cobranças'
        },
        {
            id: 31,
            pergunta: 'Posso cancelar minha assinatura a qualquer momento? Há alguma taxa de cancelamento?',
            resposta: 'O cancelamento pode ser feito a qualquer momento e não há nenhuma taxa de cancelamento. A respeito da devolução de valores, deve ser consultado diretamente com o setor financeiro, atráves do e-mail financeiro@bookinghub.com.br.',
            categoria: 'planos-cobranças'
        },
        {
            id: 32,
            pergunta: 'É oferecido descontos para planos anuais?',
            resposta: 'Sim, os planos anuais possuem desconto em relação aos planos mensais. Para o plano básico, o desconto é de 15% se comparados com 1 ano de pagamento do plano mensal, para o plano plus o desconto é de pouco mais de 16% e para o plano profissional o desconto é de 18%.',
            categoria: 'planos-cobranças'
        },
        {
            id: 33,
            pergunta: 'Como faço para atualizar ou alterar meu método de pagamento?',
            resposta: 'A alteração ou atualização do método de pagamento pode ser feito dentro da plataforma, seguindo a opção do menu "Gestão" e depois acessando a aba "Faturamento".',
            categoria: 'planos-cobranças'
        },
    ];
    
    function filtrarFAQ(value) {
        // if (categoriaSelecionada === "") {
        //     return value;
        // } else {
            return value.categoria === categoriaSelecionada && ( value.pergunta.toLowerCase().includes(finds.toLowerCase()) || value.resposta.toLowerCase().includes(finds.toLowerCase()));
            // return value.servicos.some((a) => servicoFiltro.includes(a.servico_id))
        // }
    };

    return (
        <Fragment>
            <Header/>
            <main id='page-faq'>
                <section id='section-faq-faq'>
                    <h1>Ficou com alguma <span>dúvida</span>?</h1>
                    <h4>Veja abaixo as principais dúvidas de nossos clientes e encontre a resposta para a sua!</h4>
                    <div id='categories-faq'>
                        <div className={categoriaSelecionada === 'principais-duvidas' ? 'category-faq-active' : null} onClick={() => {setCategoriaSelecionada('principais-duvidas'); setQuestionSelected(null)}}>
                            {categoriaSelecionada === 'principais-duvidas' ? <FontAwesomeIcon icon={faCheck}/> : null}
                            <p>Principais dúvidas</p>
                        </div>
                        <div className={categoriaSelecionada === 'plataforma' ? 'category-faq-active' : null} onClick={() => {setCategoriaSelecionada('plataforma'); setQuestionSelected(null)}}>
                            {categoriaSelecionada === 'plataforma' ? <FontAwesomeIcon icon={faCheck}/> : null}
                            <p>A plataforma</p>
                        </div>
                        <div className={categoriaSelecionada === 'planos-cobranças' ? 'category-faq-active' : null} onClick={() => {setCategoriaSelecionada('planos-cobranças'); setQuestionSelected(null)}}>
                            {categoriaSelecionada === 'planos-cobranças' ? <FontAwesomeIcon icon={faCheck}/> : null}
                            <p>Planos e cobranças</p>
                        </div>
                    </div>
                    <div id='search-container'>
                        <input type='text' placeholder='Busque por palavra-chave' onChange={(e) => {
                            setFinds(e.target.value);
                            // if (e.target.value.length > 0) { 
                            //     setFinds(e.target.value); setCategoriaSelecionadaAntiga(categoriaSelecionada); setCategoriaSelecionada("") 
                            // } else { 
                            //     setCategoriaSelecionada(categoriaSelecionadaAntiga) 
                            // }
                            
                            }}/>
                        <FontAwesomeIcon icon={faSearch}/>
                    </div>
                    <div id='faq-container'>
                        <ul>
                            {faqs.filter(filtrarFAQ).length === 0 ?
                                <p style={{textAlign: 'center', fontSize: '.9em'}}>Não encontramos nenhum resultado para sua busca.</p>
                            : 
                                faqs.filter(filtrarFAQ).map((item, index) => (
                                    <li key={index} onClick={() => toggleFaq(item.id)}>
                                        <div className='title-question-faq'>
                                            <h6>{item.pergunta}</h6>
                                            <FontAwesomeIcon icon={questionSelected === item.id ? faAngleUp : faAngleDown}/>
                                        </div>
                                        <div className={questionSelected === item.id ? 'content-answer show-content-answer' : 'content-answer'}>
                                            <p>{item.resposta}</p>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <h5>Não encontrou sua dúvida? Clique <a href='/fale-conosco'>aqui</a> e envie uma mensagem para nós!</h5>
                </section>
            </main>
            <Footer/>
        </Fragment>
    );
};
   
export default FaqScreen;